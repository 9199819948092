<template>
  <div
    :class="`pa-2 col-${col.sm} col-lg-${col.lg}`"
    :style="{ height: layout.height ? layout.height : '' }"
  >
    <slot>Empty</slot>
  </div>
</template>

<script>
/**
 * Component for sizing widgets
 */
export default {
  name: 'SBSWidget',
  props: {
    layout: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    col() {
      return {
        sm: this.layout?.col?.sm || 12,
        lg: this.layout?.col?.lg || 6,
      };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
