<template>
  <v-card class="full-height d-flex flex-column" elevation="0">
    <v-card-title
        class="widget-gradient white--text"
        :style="{
        paddingTop: '8px',
        paddingBottom: '8px',
      }"
    >
      <div class="d-flex flex-grow-1 justify-space-between">
        <div>
          <span>{{ $t('system.sessions.multi') | capitalize }}</span>
        </div>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div>
                <v-progress-circular
                  v-if="$wait.is('project.sbs.sessions')"
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-icon
                  v-else
                  class="ant-icon"
                  v-on="on"
                  @click="fetch(sbsObject?.project, sbsObject?.code)"
                >
                  mdi-refresh
                </v-icon>
              </div>
            </template>
            <span>
              {{
                $t('system.sbsOverview.reload', {
                  item: $t('system.sessions.multi'),
                })
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-card-title>
    <v-divider/>
    <v-card-text v-if="!sbsObject" class="d-flex flex-grow-1 align-center justify-center">
      {{ $t('system.sbsOverview.notSelected') }}
    </v-card-text>
    <div v-else class="d-flex flex-grow-1 flex-column overflow-y-auto">
      <div v-if="sbsSessions && sbsSessions.length">
        <session-item
          v-for="session in sbsSessions"
          :key="session.id"
          :session="session"
          target="_blank"
        />
      </div>
      <div v-else class="fill-height d-flex align-center justify-center" >
        {{
          $t('system.sbsOverview.noFound', {
            item: $t('system.sessions.multi'),
          })
        }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SessionItem from '@/components/Project/SessionItem.vue';

export default {
  name: 'SBSSessionsWidget',
  components: {
    SessionItem,
  },
  props: {
    sbsObject: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters(['sbsSessions']),
  },
  watch: {
    sbsObject: {
      handler(val) {
        this.$store.commit('reset_sbs_state')
        if (!val) {
          return;
        }
        const { project: projectId, code } = val;
        this.fetch(projectId, code);
      },
      immediate: true,
    },
  },
  methods: {
    fetch(projectId, code) {
      if (projectId && code) {
        this.$store.dispatch('fetchProjectSBSSessions', {
          projectId,
          code,
        });
      }
    },
  },
};
</script>

<style scoped></style>
