<template>
  <div class="d-flex flex-grow-1 overflow-y-auto">
    <div class="d-flex flex-column flex-grow-1" style="width: 0">
      <div class="px-2 pt-2">
        <div v-if="selectedSbsObject">
          <div>
            <span class="font-weight-bold">{{ $t('general.code') }}:</span>
            {{ selectedSbsObject.code }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('general.label') }}:</span>
            {{ selectedSbsObject.label }}
          </div>
        </div>
        <div v-else>{{ $t('system.sbsOverview.selectSBS') }}</div>
      </div>
      <div class="d-flex flex-wrap flex-grow-1 align-content-start pa-1">
        <s-b-s-widget :layout="{ col: { lg: 12 }, height: '400px' }">
          <s-b-s-tables-widget :sbs-object="selectedSbsObject" />
        </s-b-s-widget>
        <s-b-s-widget :layout="{ height: '300px' }">
          <s-b-s-tasks-widget :sbs-object="selectedSbsObject" />
        </s-b-s-widget>
        <s-b-s-widget :layout="{ height: '300px' }">
          <s-b-s-sessions-widget :sbs-object="selectedSbsObject" />
        </s-b-s-widget>
        <s-b-s-widget :layout="{ col: { lg: 12 }, height: '400px' }">
          <s-b-s-rfi-widget :sbs-object="selectedSbsObject" />
        </s-b-s-widget>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SBSWidget from '@/components/Project/SBS/Widgets/SBSWidget';
import SBSTablesWidget from '@/components/Project/SBS/Widgets/SBSTablesWidget';
import SBSTasksWidget from '@/components/Project/SBS/Widgets/SBSTasksWidget';
import SBSSessionsWidget from '@/components/Project/SBS/Widgets/SBSSessionsWidget.vue';
import SBSRfiWidget from '@/components/Project/SBS/Widgets/SBSRfiWidget.vue';
// import AntLoading from '@/components/AntLoading';

export default {
  name: 'SbsOverview',
  components: {
    SBSRfiWidget,
    SBSSessionsWidget,
    SBSTasksWidget,
    SBSWidget,
    SBSTablesWidget,
    // AntLoading,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('deselect_sbs_object');
    next();
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(['selectedSbsObject']),
  },
  watch: {},
  mounted() {
    this.$store.commit('open_sbs_sidebar');
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.active-version {
  background-color: white;
}
</style>
