import { render, staticRenderFns } from "./SbsManagement.vue?vue&type=template&id=73234bad&scoped=true"
import script from "./SbsManagement.vue?vue&type=script&lang=js"
export * from "./SbsManagement.vue?vue&type=script&lang=js"
import style0 from "./SbsManagement.vue?vue&type=style&index=0&id=73234bad&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73234bad",
  null
  
)

export default component.exports