<template>
  <v-card class="full-height d-flex flex-column" elevation="0">
    <v-card-title
      class="widget-gradient white--text"
      :style="{
        paddingTop: '8px',
        paddingBottom: '8px',
      }"
    >
      <div class="d-flex flex-grow-1 justify-space-between">
        <div>
          <span>{{ $t('system.tasks.rfi.multi') | capitalize }}</span>
        </div>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div>
                <v-progress-circular
                  v-if="$wait.is('tasks.rfi.get')"
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-icon v-else class="ant-icon" v-on="on" @click="fetch">
                  mdi-refresh
                </v-icon>
              </div>
            </template>
            <span>
              {{
                $t('system.sbsOverview.reload', {
                  item: $t('system.tasks.rfi.multi'),
                })
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text
      v-if="!sbsObject"
      class="pt-6 d-flex flex-grow-1 align-center justify-center"
    >
      {{ $t('system.sbsOverview.notSelected') }}
    </v-card-text>
    <div v-else class="d-flex flex-grow-1 flex-column overflow-y-auto">
      <div v-if="tasksRfi && tasksRfi.length" class="full-width">
        <task-v2
          v-for="task in tasksRfi"
          :key="task.id"
          :task="task"
          :target-blank="true"
        >
          <template #chips>
            <v-chip v-if="task.task_type.rfi_module" x-small>
              {{ task.task_type.rfi_module }}
            </v-chip>
            <v-divider
              v-if="task.task_type.rfi_module"
              vertical
              inset
              class="mx-2"
            />
            <v-chip x-small>
              {{ task.task_type.rfi_type }}
            </v-chip>
            <v-divider vertical inset class="mx-2" />
          </template>
        </task-v2>
      </div>
      <div v-else class="fill-height d-flex align-center justify-center">
        {{
          $t('system.sbsOverview.noFound', {
            item: $t('system.tasks.rfi.multi'),
          })
        }}
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import TaskV2 from '@/components/Tasks/TaskV2';

export default {
  name: 'SBSRfiWidget',
  components: {
    TaskV2,
  },
  props: {
    sbsObject: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    filterCode: null,
  }),
  computed: {
    ...mapGetters(['tasksRfi']),
  },
  watch: {
    sbsObject: {
      handler(val) {
        this.$store.commit("reset_rfis")
        if (!val) {
          this.filterCode = null;
          return;
        }
        this.filterCode = {
          column: 'sbscode',
          operator: '=',
          values: [val.code],
        };
        this.fetch();
      },
      immediate: true,
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchRfiTasks', {
        advanced: this.createAdvancedFilters(),
      });
    },
    createAdvancedFilters() {
      const filters = [];
      if (this.filterCode) filters.push(this.filterCode);
      return filters;
    },
  },
};
</script>
